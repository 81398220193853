/* .main-content.guest {
	background-image: url('../resources/images/admatic-bg.jpg');
	min-height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
} */

body, .makeStyles-bgDefault-1, .makeStyles-bgDefault-216, .makeStyles-bgDefault-219 {
	background-color: #fff !important;
}

.react-bootstrap-table td{
    white-space: normal !important;
}

.login-logo {
	width:  200px;
	cursor: pointer;
}

.login-logo.qf {
	width: 100px;
}

.logo-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.makeStyles-logoClasses-16, .jss16 {
	max-height: 6rem !important;
}

.terms-conditions {
	padding: 5%;
}
.terms-conditions p, .terms-conditions li {
	color: #525f7f;
}

.full-page-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.large-icon {
  	font-size: '50px' !important;
}

.add-logo-dropzone {
	width: 250px;
	margin:  0 auto;
}

.category-card {
	height: 250px;
	cursor:  pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.category-card:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.category-card p {
	margin: 0;
	font-size: 1.5rem;
}

.MuiPickersCalendarHeader-transitionContainer {
	overflow: unset !important;
}

.bold-text {
	font-weight: 700;
}

.hover-text {
	cursor:  pointer;
}

.hover-text:hover {
	text-decoration: underline;
}

.MuiOutlinedInput-input.Mui-disabled {
    background-color: #e9ecef;
}

.dropzone {
	text-align: center;
	cursor:  pointer;
}

.category-icon {
	font-size: 2.5rem !important;
	width:  2.5rem !important;
	height:  2.5rem !important;
}

.register-icon {
	font-size: 3rem !important;
	width:  3rem !important;
	height:  3rem !important;
	color:  #2dce89;
}

.success-text {
	color:  #2dce89;
	margin-top: 2px;
	margin-bottom: 0;
}

.image-modal-img {
	max-width: 100%;
}

.file-added-name {
	color:  #015f63;
}

.powered-by {
	cursor: pointer;
}

/* home */

.home-check-icon {
	font-size: 2rem !important;
	width:  2rem !important;
	height:  2rem !important;
	color:  #fff;
	margin-right: 0.5rem;
}

.home-logo {
	width:  300px;
}

/*.MuiBox-root-107 {
	position: initial !important;
}*/

.hover-text {
	cursor: pointer;
}

.red-text {
	color: #f5365c !important;
}

.primary-text {
	color: #015f63;
}

.profile-accordion {
	padding-top: 2em !important;
}

.MuiAccordionSummary-content {
	justify-content: space-between !important;
	align-items: center !important;
}

.dataTables_filter {
	padding-left: 0.5rem !important;
}

.fc-event.completed {
	color: #eee;
}

.account-type-badge {
	color: #5fb2ee;
	border: 1px solid #5fb2ee;
	border-radius: 5px;
	padding: 0.5rem;
	opacity: 0.7;
}

.online-discount {
	color: #5fb2ee;
	border: 1px solid #5fb2ee;
	border-radius: 5px;
	padding: 0.5rem;
}

.stage-color {
	width: 8px;
	height: 8px;
	margin-right: 0.4rem;
	border-radius: 50%;
}

.stage-text {
	font-size: 0.75rem;
}

.pipeline-btn-group {
	display: flex !important;
}

.pipeline-btn {
	flex: 1;
}

.react-bootstrap-table .filter-label {
	width: 100%;
}

.fixed-height-modal .MuiDialog-paperScrollPaper {
	height: calc(100% - 64px);
}

.quote-details-card {
	position: sticky !important;
	top: 1rem !important;
}

.lead-modal .MuiDialog-paperScrollPaper {
	height: calc(100% - 64px) !important;
}

.stage-select .MuiSelect-select {
	display: flex !important;
	flex-direction: row;
	align-items: center;
}

.MuiMenuItem-root {
	display: flex !important;
	flex-direction: row;
	align-items: center;
}

.react-bootstrap-table th[data-row-selection] {
	width: 50px;
}

.activity-container p {
	color: #525f7f;
}

.task-container.past p {
	opacity: 0.3;
}

.checkbox-menu-item .MuiListItemText-primary{
	font-size: 1rem !important;
}

.normal-text {
	color: #525f7f;
	text-align: left;
	margin-bottom: 0.2rem;
}

.warning-text {
	color: #fb6340;
}

.inactive-delivery {
	opacity: 0.3;
}


.q-tip {
	position: relative;
  	display: inline-block;
}
  
.q-tip .tooltiptext {
	display: none;
	width: 200px;
	background-color: #076b9d;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 50%;
	margin-left: -60px;
}

.tooltiptext.top {
	z-index: 1;
	top: -150px;
  	left: 0;
  	right: 0;
	margin-left: 300px;
}

.tooltiptext {
	font-size: 12px;
}

.q-tip:hover .tooltiptext {
	display: block;
}

.underline-text {
	text-decoration: underline;
}

.secondary-label {
	color: #bbb !important;
	font-weight: 400 !important;
}

.normal-p {
	color: #525f7f;
}

.delete-text {
	font-size: 0.9rem;
	color: #bbb;
}

.video-box {
	border: 1px solid #bbb;
	border-radius: 5px;
	width: 300px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.file-upload-box {
	cursor: pointer;
	border: 1px solid #bbb;
	border-radius: 5px;
	border-style: dashed;
	width: 220px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.file-upload-box:hover {
	background-color: #eee;
}

.file-upload-box i {
	font-size: 20px;
}

.provider-form-group {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.provider-form-group.selected {
	background-color: #eee;
}

.provider-form-group:hover {
	background-color: #eee;
}

.provider-logo {
	width: 80px;
}

.provider-logo.home {
	width: 120px;
}

.select-day {
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.select-day p {
	margin: 0;
}

.select-day.nph {
	border: 2px solid #015f63;
}

.select-day.pc {
	border: 2px solid #076b9d;
}

.select-day.qf {
	border: 2px solid #2257bb;
}

.home-logo-container {
	justify-content: space-evenly;
}

.tax-relief-box {
	border-radius: 5px;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.tax-relief-box.nph {
	border: 2px solid #015f63;
}

.tax-relief-box.pc {
	border: 2px solid #076b9d;
}

.tax-relief-box.qf {
	border: 2px solid #2257bb;
}

.tax-relief-box p {
	margin-top: 0rem;
	margin-bottom: 1rem;
}

.select-answer {
	cursor: pointer;
}

.select-answer:hover {
	background-color: #eee;
}

.reg-subtext {
	font-size: 12px !important;
	margin-top: 0.5rem !important;
	font-weight: 300 !important;
}

.nph {
	color: #015f63 !important;
}

.pc {
	color: #076b9d !important;
}

.qf {
	color: #2257bb !important;
}

.powered-by-top {
	text-align: right;
	font-size: 24px;
	font-weight: bold;
}

.powered-by-top span {
	font-size: 12px;
	font-weight: 400;
}

@media only screen and (max-width: 768px) {
    .stepper-badge {
    	display:none;
    }

	.home-logo-container {
		justify-content: center;
	}

	.powered-by-top {
		text-align: center;
		font-size: 18px;
	}

	.login-logo-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.auth-header {
		padding-top: 2rem !important;
	}
}